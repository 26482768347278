import { sendToDataLayer } from '@utils';

const PRIVACY_EVENT_CATEGORY = 'content_privacy_section';

export const trackPrivacyContact = () =>
  sendToDataLayer(undefined, {
    event_category: PRIVACY_EVENT_CATEGORY,
    event_action: 'click',
    event_label: `contact_us`
  });
