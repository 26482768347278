import { FunctionComponent } from 'react';
import { DefaultProps } from '../interface';

import { trackPrivacyContact } from './tracking';
import {
  declaredDate,
  entitle,
  collectData,
  collectedMethod,
  purposesOfDataUsage,
  securityOfPersonalData,
  durationOfKeepingPersonalData,
  disclosureOfPersonalData,
  dataOwnerRights,
  rightsUsageAndContact,
  effectFromNotGivingConsent,
  dataTransferToForeignCountry,
  otherPersonalDataPolicy,
  appliedLaw,
  personalPrivacyPolicyChanging
} from './content';
import './privacy.scss';

const PrivacySection: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const collectDataText = collectData[prefix];
  const collectedMethodText = collectedMethod[prefix];
  const purposesOfDataUsageText = purposesOfDataUsage[prefix];
  const securityOfPersonalDataText = securityOfPersonalData[prefix];
  const durationOfKeepingPersonalDataText = durationOfKeepingPersonalData[prefix];
  const disclosureOfPersonalDataText = disclosureOfPersonalData[prefix];
  const dataOwnerRightsText = dataOwnerRights[prefix];
  const rightsUsageAndContactText = rightsUsageAndContact[prefix];
  const effectFromNotGivingConsentText = effectFromNotGivingConsent[prefix];
  const dataTransferToForeignCountryText = dataTransferToForeignCountry[prefix];
  const otherPersonalDataPolicyText = otherPersonalDataPolicy[prefix];
  const appliedLawText = appliedLaw[prefix];
  const personalPrivacyPolicyChangingText = personalPrivacyPolicyChanging[prefix];

  return (
    <div className="privacy">
      <p className="mb-2" style={{ marginTop: -10 }}>
        {declaredDate[prefix]}
      </p>
      <p className="mb-2">{entitle[prefix]}</p>

      {/* BEGIN: 1.ข้อมูลส่วนบุคคลที่มีการเก็บรวบรวม  */}
      <div>
        <h2 className="mb-2">{collectDataText.title}</h2>
        <div className="mb-2">
          {collectDataText.data[0]}
          <ol className="ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectDataText.data[1]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li className="mb-0">{collectDataText.data[2]}</li>
              <li className="mb-0">{collectDataText.data[3]}</li>
              <li className="mb-0">{collectDataText.data[4]}</li>
              <li className="mb-0">{collectDataText.data[5]}</li>
            </ol>
          </ol>
          <ol className="ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectDataText.data[6]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li className="mb-0">{collectDataText.data[7]}</li>
              <li className="mb-0">{collectDataText.data[8]}</li>
              <li className="mb-0">{collectDataText.data[9]}</li>
              <li className="mb-0">{collectDataText.data[10]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectDataText.data[11]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{collectDataText.data[12]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectDataText.data[13]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{collectDataText.data[14]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectDataText.data[15]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{collectDataText.data[16]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectDataText.data[17]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{collectDataText.data[18]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectDataText.data[19]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{collectDataText.data[20]}</li>
            </ol>
          </ol>
          <ol className="ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectDataText.data[21]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li className="mb-0">{collectDataText.data[22]}</li>
              <li className="mb-0">{collectDataText.data[23]}</li>
              <li className="mb-0">{collectDataText.data[24]}</li>
              <li className="mb-0">{collectDataText.data[25]}</li>
            </ol>
          </ol>
        </div>
      </div>
      {/* END: 1.ข้อมูลส่วนบุคคลที่มีการเก็บรวบรวม  */}

      {/* BEGIN: 2.	วิธีการจัดเก็บข้อมูลส่วนบุคคล  */}
      <div>
        <h2 className="mb-2">{collectedMethodText.title}</h2>
        <div className="mb-2">{collectedMethodText.data[0]}</div>
        <div className="mb-2">
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectedMethodText.data[1]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{collectedMethodText.data[2]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectedMethodText.data[3]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{collectedMethodText.data[4]}</li>
            </ol>
          </ol>
          <ol className="ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{collectedMethodText.data[5]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li className="mb-0">{collectedMethodText.data[6]}</li>
              <li className="mb-0">{collectedMethodText.data[7]}</li>
              <li className="mb-0">{collectedMethodText.data[8]}</li>
              <li className="mb-0">{collectedMethodText.data[9]}</li>
              <li className="mb-0">{collectedMethodText.data[10]}</li>
              <li className="mb-0">{collectedMethodText.data[11]}</li>
            </ol>
          </ol>
        </div>
      </div>
      {/* END: 2.	วิธีการจัดเก็บข้อมูลส่วนบุคคล  */}

      {/* BEGIN: 3.	วัตถุประสงค์ในการใช้ข้อมูลส่วนบุคคล  */}
      <div>
        <h2 className="mb-2">{purposesOfDataUsageText.title}</h2>
        <div className="mb-2">{purposesOfDataUsageText.data[0]}</div>
        {purposesOfDataUsageText.table && (
          <table className="purpose-table mb-2">
            <thead>
              <tr>
                {purposesOfDataUsageText.table.header &&
                  purposesOfDataUsageText.table.header.map((row, i) => <th key={i}>{row}</th>)}
              </tr>
            </thead>
            {purposesOfDataUsageText.table.data.map((row, i) => (
              <tr key={i}>
                {row.map((r, i) => (
                  <td key={i}>{r}</td>
                ))}
              </tr>
            ))}
          </table>
        )}
      </div>
      {/* END: 3.	วัตถุประสงค์ในการใช้ข้อมูลส่วนบุคคล  */}

      {/* BEGIN: 4.	การรักษาความปลอดภัยของข้อมูลส่วนบุคคล */}
      <div>
        <h2 className="mb-2">{securityOfPersonalDataText.title}</h2>
        <div className="mb-2">{securityOfPersonalDataText.data[0]}</div>
      </div>
      {/* END: 4.	การรักษาความปลอดภัยของข้อมูลส่วนบุคคล */}

      {/* BEGIN: 5.	ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล */}
      <div>
        <h2 className="mb-2">{durationOfKeepingPersonalDataText.title}</h2>
        <div className="mb-2">{durationOfKeepingPersonalDataText.data[0]}</div>
      </div>
      {/* END: 5.	ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล */}

      {/* BEGIN: 6.	การเปิดเผยข้อมูลส่วนบุคคล */}
      <div>
        <h2 className="mb-2">{disclosureOfPersonalDataText.title}</h2>
        <div className="mb-2">
          {disclosureOfPersonalDataText.data[0]}
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{disclosureOfPersonalDataText.data[1]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{disclosureOfPersonalDataText.data[2]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{disclosureOfPersonalDataText.data[3]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{disclosureOfPersonalDataText.data[4]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{disclosureOfPersonalDataText.data[5]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{disclosureOfPersonalDataText.data[6]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{disclosureOfPersonalDataText.data[7]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{disclosureOfPersonalDataText.data[8]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{disclosureOfPersonalDataText.data[9]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{disclosureOfPersonalDataText.data[10]}</li>
            </ol>
          </ol>
        </div>
      </div>
      {/* END: 6.	การเปิดเผยข้อมูลส่วนบุคคล */}

      {/* BEGIN: 7.	สิทธิของเจ้าของข้อมูล */}
      <div>
        <h2 className="mb-2">{dataOwnerRightsText.title}</h2>
        <div className="mb-2">
          {dataOwnerRightsText.data[0]}
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{dataOwnerRightsText.data[1]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{dataOwnerRightsText.data[2]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{dataOwnerRightsText.data[3]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{dataOwnerRightsText.data[4]}</li>
            </ol>
          </ol>
          <ol className="ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{dataOwnerRightsText.data[5]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li className="mb-0">{dataOwnerRightsText.data[6]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[7]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[8]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[9]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{dataOwnerRightsText.data[10]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{dataOwnerRightsText.data[11]}</li>
            </ol>
          </ol>
          <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{dataOwnerRightsText.data[12]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{dataOwnerRightsText.data[13]}</li>
            </ol>
          </ol>
          <ol className="ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{dataOwnerRightsText.data[14]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li className="mb-0">{dataOwnerRightsText.data[15]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[16]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[17]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[18]}</li>
              <li>{dataOwnerRightsText.data[19]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[20]}</li>
            </ol>
          </ol>
          <ol className="ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{dataOwnerRightsText.data[21]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li className="mb-0">{dataOwnerRightsText.data[22]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[23]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[24]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[25]}</li>
              <li className="mb-0">{dataOwnerRightsText.data[26]}</li>
            </ol>
          </ol>
          <ol className="ml-2" style={{ listStyleType: 'none' }}>
            <li className="mb-0">
              <u>{dataOwnerRightsText.data[27]}</u>
            </li>
            <ol className="mb-0 ml-2" style={{ listStyleType: 'none' }}>
              <li>{dataOwnerRightsText.data[28]}</li>
            </ol>
          </ol>
        </div>
      </div>
      {/* END: 7.	สิทธิของเจ้าของข้อมูล */}

      {/* BEGIN: 8.	การใช้สิทธิและการติดต่อ */}
      <div>
        <h2 className="mb-2">{rightsUsageAndContactText.title}</h2>
        <div className="mb-2">
          <p className="mb-2">{rightsUsageAndContactText.data[0]}</p>
          {rightsUsageAndContactText.table && (
            <table className="contact-table mb-2">
              {rightsUsageAndContactText.table.data.map((row, i) => (
                <tr key={i}>
                  {row.map((r, i) => (
                    <td key={i}>{r}</td>
                  ))}
                </tr>
              ))}
            </table>
          )}
          <p className="mb-2">{rightsUsageAndContactText.data[1]}</p>
        </div>
      </div>
      {/* END: 8.	การใช้สิทธิและการติดต่อ */}

      {/* BEGIN: 9.	ผลกระทบจากการไม่ให้ข้อมูลส่วนบุคคล */}
      <div>
        <h2 className="mb-2">{effectFromNotGivingConsentText.title}</h2>
        <div className="mb-2">
          <p className="mb-2">{effectFromNotGivingConsentText.data[0]}</p>
          <p className="mb-2">{effectFromNotGivingConsentText.data[1]}</p>
        </div>
      </div>
      {/* END: 9.	ผลกระทบจากการไม่ให้ข้อมูลส่วนบุคคล */}

      {/* BEGIN: 10. การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ */}
      <div>
        <h2 className="mb-2">{dataTransferToForeignCountryText.title}</h2>
        <p className="mb-2">{dataTransferToForeignCountryText.data[0]}</p>
      </div>
      {/* END: 10. การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ */}

      {/* BEGIN: 11. นโยบายคุ้มครองข้อมูลส่วนบุคคลอื่น */}
      <div>
        <h2 className="mb-2">{otherPersonalDataPolicyText.title}</h2>
        <p className="mb-2">{otherPersonalDataPolicyText.data[0]}</p>
      </div>
      {/* END: 11. นโยบายคุ้มครองข้อมูลส่วนบุคคลอื่น */}

      {/* BEGIN: 12. กฎหมายที่ใช้บังคับ */}
      <div>
        <h2 className="mb-2">{appliedLawText.title}</h2>
        <p className="mb-2">{appliedLawText.data[0]}</p>
      </div>
      {/* END: 12. กฎหมายที่ใช้บังคับ */}

      {/* BEGIN: 13.	การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว */}
      <div>
        <h2 className="mb-2">{personalPrivacyPolicyChangingText.title}</h2>
        <p className="mb-2">{personalPrivacyPolicyChangingText.data[0]}</p>
      </div>
      {/* END: 13.	การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว */}

      <a href="/th/contact" className="mb-2" onClick={trackPrivacyContact}>
        ติดต่อเรา
      </a>
    </div>
  );
};

export default PrivacySection;
